.publicaciones-container {
  height: 100%;
  background: linear-gradient(to bottom, #590802, #87352f);
  padding-top: 40px;
}
.publicaciones-container div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.publicaciones-container h2 {
  font-size: 3rem;
  padding-top: 35px;
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
  color: white;
  text-shadow: 0.7px 0.7px 0.7px black;
}
.ol-publicaciones {
  color: #fff;
  list-style: disc;
  margin: 0px 15px;
}
.ol-publicaciones li {
  font-size: 1.2rem;
  margin: 10px;
}
.prox-publicaciones {
  background: linear-gradient(to right, #59080215, #87352f, #59080215);
  margin: 0px 150px;
}
.prox-publicaciones h2 {
  font-size: 2.2rem;
}
.rosas-background{
position: absolute;
bottom: 5px;
right: 5px;
width: 400px;
opacity: 65%;
}

.link-cv{
  left: 12px;
  position: fixed;
}

@media screen and (max-width: 680px) {
  .rosas-background{
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 275px;
  opacity: 30%;
  }
}