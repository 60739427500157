/* FONTS */
h1 {
  font-family: "Satisfy", cursive;
}
h2 {
  font-family: "Courgette", cursive;
}

h3,
h4 {
  font-family: "Poppins", sans-serif;
}

p{
  font-family: "Open Sans", sans-serif;
}
a {
  font-family: "Open Sans", sans-serif;
  color: rgb(43, 37, 37);
}
