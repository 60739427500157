.home-container {
  text-align: center;
}
.home-container section {
  margin: 30px 30px 40px;
}
.home-container img {
  width: 80vw;
  max-height: 85vh;
  aspect-ratio: 3/2;
}

.logo-titulo-container {
  background-image: 
  /* linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.4)
    ), */
    url("./../../img/portada.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;

  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.logo-home {
  width: 150px !important;
  height: 150px !important;
  border-radius: 500px;
  padding: 15px;
  text-align: end !important;
  box-shadow: 0px 0px 15px 5px rgba(47, 44, 44, 0.874);
  position: absolute;
  right: 70px;
  top: 140px;
}
.titulo-home {
  /* margin: 30px; */
  font-size: 8rem;
  color: white;
  text-shadow: 1px 5px 15px black;
  cursor: default;
}

@media screen and (max-width: 800px) {
  .logo-titulo-container {
    height: 40vh;
  }
  .logo-home {
    width: 100px !important;
    height: 100px !important;
    margin: 20px;
    position:static;
  }
  .titulo-home {
    margin: 20px;
    font-size: 3rem;
  }
}
@media screen and (max-width: 800px) {
  .logo-titulo-container {
    flex-direction: column;
  }
  .logo-home {
    width: 80px !important;
    height: 80px !important;
    margin: 10px;
  }
}

.primer-texto-home {
  margin: 20px 80px;
  font-style: italic;
  font-size: 1.5rem;
  font-weight: 400;
  color: rgb(31, 29, 29);
}

.carousel-control-next {
  right: 40px;
}

.carousel-control-prev {
  left: 40px;
}
.carousel-container {
  display: grid;
  justify-content: center;
  align-items: center;
}
.carousel-indicators {
  gap: 5px;
  margin-bottom: 10px;
}
.carousel-indicators button {
  border: none;
  background-color: white;
  width: 30px;
}
.carousel-caption {
  background: rgba(37, 25, 35, 0.624);
  padding-left: 10px;
  padding-right: 10px;
}
.carousel-caption h4 {
  color: #fff;
  text-shadow: 1px 1px 2px black;
  font-size: 2rem;
}
.carousel-caption p {
  color: #fff;
  text-shadow: 1px 1px 2px black;
  font-size: 1.8rem;
}

.carousel-img-home {
  max-height: 90vh !important;
}
.bienvenidos-home-div h2 {
  color: #020202;
  margin: 20px 10vw;
  text-shadow: 1px 2px 2px rgba(113, 77, 77, 0.713);
  font-size: 2.3rem;
}
@media screen and (min-width: 771px) {
  .bienvenidos-home-div {
    /* position: absolute;
    z-index: 1; */
    margin: 30px;
    text-align: left;
  }
}

.presentacion-home {
  background-image: url("./../../img/presentacion.jpg");
  background-size: cover;
  background-position: bottom;
  padding: 150px 0px;
  text-align: right;
}
.presentacion-home_div {
  background: rgba(37, 25, 35, 0.624);
  padding: 30px;
  margin: 0px 50px;
}
.presentacion-home p {
  color: #fff;
  text-shadow: 1px 2px 2px black;
  font-size: 2rem;
  font-weight: 800;
}
.presentacion-home a {
  color: #fff;
  background: rgba(37, 25, 35, 0.624);
  padding: 5px;
  border-radius: 5px;
  text-shadow: 1px 2px 2px black;
  font-size: 1.7rem;
  font-weight: 600;
}
.div-saber-mas {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .bienvenidos-home-div h2 {
    margin: 20px 30px;
    font-size: 1.7rem;
  }
  .carousel-caption p {
    font-size: 1.5rem;
  }
  .carousel-caption h4 {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 771px) {
  .bienvenidos-home-div h2 {
    margin: 20px 10px;
    font-size: 1.6rem;
    color: rgb(28, 26, 26);
    text-shadow: none;
  }
  .carousel-caption p {
    font-size: 1.1rem;
  }
  .carousel-caption h4 {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 600px) {
  .primer-texto-home {
    margin: 15px 25px;
    font-size: 1rem;
  }
  .presentacion-home {
    padding: 0px;
  }
  .presentacion-home div {
    padding: 20px;
    margin: 0px 0px;
  }
  .presentacion-home p {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .presentacion-home a {
    font-size: 1.2rem;
    font-weight: 400;
  }
}
@media screen and (max-width: 457px) {
  .carousel-caption {
    padding: 2px;
  }
  .carousel-caption p {
    font-size: 1rem;
  }
  .carousel-caption h4 {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 350px) {
  .carousel-caption {
    padding: 2px;
  }
  .carousel-caption p {
    font-size: 0.8rem;
  }
  .carousel-caption h4 {
    font-size: 1rem;
  }
}
