.videos-article {
  text-align: center;
  margin: 40px;
}
.videos-article h2 {
  font-size: 3rem;
  padding-top: 15px;
  margin-bottom: 40px;
  text-shadow: 0.7px 0.7px 0.7px black;
}
.videos-container {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template: auto/1fr 1fr;
}
.video-section {
  margin: 15px;
}

@media screen and (max-width: 1170px) {
  .videos-container {
    grid-template: 1fr / auto;
  }
}
@media screen and (max-width: 580px) {
  .video-section iframe {
    width: 320px;
    height: 200px;
  }
  .videos-article h2{
    margin: 15px;
  }
}
