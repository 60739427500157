.header-container {
  display: flex;
  flex-direction: row;
  font-size: 1.3rem;
  box-shadow: 0px 0px 10px 0px rgba(157, 98, 98, 0.874);
}
.header-container a{
color: white !important;
font-weight: 600;
text-shadow: 1px 1px 1px rgba(29, 28, 28, 0.8);
}
.logo-header{
width: 40px;
/* background-color: white; */
border-radius: 120px;
padding: 2px;
/* box-shadow: 0px 0px 5px 0px rgba(157, 98, 98, 0.874); */
box-shadow: 0px 0px 5px 0px rgba(47, 44, 44, 0.874);
}
.footer a{
font-size: small;
color: white !important;
font-weight: 600;
text-shadow: 1px 1px 1px rgba(29, 28, 28, 0.8);
}

@media screen and (max-width:991px ) {
  .header-container {
    justify-content:space-between ;
  }
}

